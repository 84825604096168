<template>
  <div class="min-w-screen min-h-screen bg-red-100 flex items-center justify-center px-5 py-5">
    <div>
      <div @click="goToWebsite()" class="w-full flex flex-row justify-center mb-5 cursor-pointer">
        <img class="w-20" src="./assets/favicon.svg" alt="" srcset="">
      </div>
      <a v-if="isValidToken" href="https://slack.com/oauth/v2/authorize?client_id=5004300486038.5023551705169&scope=chat:write,chat:write.customize,chat:write.public,links:write,commands,files:write,groups:read,im:write,mpim:write,links.embed:write&user_scope=chat:write,files:read,files:write,links.embed:write,links:read,links:write"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>

      <div v-if="!isLoading" class="w-full mx-auto rounded-3xl shadow-lg bg-white px-10 pt-16 text-gray-600" style="max-width: 400px">
        <div class="overflow-hidden relative mb-10">
          <div class="overflow-hidden relative cursor-grab relative">
            <div class="w-full overflow-hidden text-center">
              <!-- <img src="./assets/logo.png" alt="" srcset=""> :style="`background-image:url('./assets/logo.png')`" -->
              <div class="w-64 h-64 border rounded-full flex items-center align-center justify-center mx-auto mb-10 overflow-hidden bg-cover bg-center">
                <img v-if="error" class="w-36" src="./assets/error.png" alt="" srcset="">
                <img v-else class="w-36" src="./assets/success.png" alt="" srcset="">
              </div>
              <h2 class="font-bold text-xl mb-3">
                <span v-if="error">Something went wrong!!!</span>
                <span v-else>Slack Stickers!!!</span>
              </h2>
              <p class="text-sm leading-tight">{{ message }}</p>

              <el-button class="my-5" @click="goToWebsite()" type="danger">
                Learn more
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElLoading } from 'element-plus'
import axios from 'axios';

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    token: "",
    error: false,
    isValidToken: true,
    isLoading: true,
    message: ""
  }),
  mounted () {
    const params = (new URL(document.location)).searchParams;
    this.token = params.get("code");

    if (!this.token || (this.token && this.token.length < 1)) {
      this.error = true
      this.isValidToken = true
      this.message = 'Invalid token'
      return
    }

    this.validateToken()
  },
  methods: {
    authenticate () {
      window.open("https://slack.com/oauth/v2/authorize?client_id=5004300486038.5023551705169&scope=chat:write,chat:write.customize,chat:write.public,links:write,commands,files:write,groups:read,im:write,mpim:write,links.embed:write&user_scope=chat:write,files:read,files:write,links.embed:write,links:read,links:write", "_blank")
    },
    validateToken () {
      const loading = ElLoading.service({
        lock: true,
        text: 'Verifying your token',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      const body = new URLSearchParams();
      body.append("client_id", process.env.VUE_APP_SLACK_CLIENT_ID);
      body.append("client_secret", process.env.VUE_APP_SLACK_CLIENT_SECRET);
      body.append("code", this.token)

      axios
      .post("https://slack.com/api/oauth.v2.access", body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(async (response) => {
        if (response.data.error) {
          this.message = `Reason: ${response.data.error}`
          this.error = true
        } else {
          // else, it went through
          this.message = "You're welcome to Slack Stickers!"

          // const payload = {
          //   team_id:response.data.team.id,
          //   user_id: response.data.authed_user.id,
          //   token: response.data.authed_user.access_token,
          //   token_type: response.data.authed_user.token_type
          // }
          await this.saveAuthDetails(response.data)
        }

        this.isLoading = false
        loading.close()
      })
      .catch(() => {
        this.error = true
        this.isLoading = false
        loading.close()
      })
    },
    async saveAuthDetails (payload) {
      const baseURL = process.env.VUE_APP_API_BASE_URL;
      await axios.post(`${baseURL}/api/v1/slack/auth`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    goToWebsite () {
      window.open("https://slackstickers.com", "_blank")
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
