import { createRouter, createWebHistory } from "vue-router"; // createWebHistory

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHistory(),
  routes: [
    {
      path: "",
      name: "home",
      component: () => import("../App.vue"),
      pathToRegexpOptions: { strict: true }
    }
  ],
  scrollBehavior () {
    return { top: 0 }
  }
});

export default router;
